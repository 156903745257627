import React from "react";

// import FileReader from "./FileReader";

const test = () => {
  return (
    <>
      <h1>Hello this is test page</h1>
      {/* <FileReader /> */}
    </>
  );
};
export default test;
